import React from "react";
import PlaceList from "./PlaceList"
import { useStaticQuery , graphql } from "gatsby";



export const getPlaces = graphql`
{
  places: allContentfulTourismData {
   edges {
     node {
       contentful_id
       name
       slug
       timeRequired
       timings
       entryFees
       images {
        gatsbyImageData(layout: CONSTRAINED)
       }
     }
   }
 }
}
`;



const Places = () => 
{
    // we extracted the places attribute that was the name of the query
    const { places } = useStaticQuery(getPlaces)
    return <PlaceList places={places}/>
}

export default Places
