import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "../../css/place.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink";

// where do we get the place object , that is what ia hve to do ,

const Place = ({ place }) =>
{   
    const { name , slug , images } = place
    // ok so get image returns a file , that is what i have to do 
    
    const image = getImage(images[0])
   
   
     return(
         <article className={styles.place}>
            <div className={styles.imgContainer}>
                
                <GatsbyImage image={image} alt="single place" className={styles.img} />
                <AniLink fade className={styles.link} to={`/places/${slug}`}>
                    details
                </AniLink>
            </div>
            <div className={styles.footer}>
                <h3> 
                    {name}
                </h3>
            </div>
         </article>
         
     )
}

 export default Place