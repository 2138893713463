import React from "react";
import Layout from "../components/Layout";
import StyledHero from "../components/StyledHero"
import { graphql } from 'gatsby'
// we have to import the component from here
import Places from '../components/places/Places'
import Seo from "../components/SEO";



export const query = graphql`
  {
    defaultBcg: file(relativePath: {eq: "defaultBcg.jpeg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;



export default function places( { data }) {
    return (
        <div>
          <Seo title="places" description="description of some nice places to visit"/>
            <Layout>
                <StyledHero img={data.defaultBcg.childImageSharp.fluid}>
                    Places page
                </StyledHero>
                <Places/>
            </Layout>
        </div>
    )
}